var testimonialsEN = [
    {
        name: "Benjamin",
        quote: "She is fantastic at teaching how to play more musically and effortlessly. Beatrix provides a clear learning path towards my goal by proposing the right pieces and exercises. And the lessons are always great fun!"
    },
    {
        name: "Matthieu",
        quote: "In only a few minutes and despite the fact that we were in videoconference, she found my positional flaws which directly allowed me to improve my playing. Beatrix is a great teacher who really allows us to evolve quickly in the learning of our instrument that we love so much!"
    },
    {
        name: "Dean",
        quote: "From your very first lesson with Beatrix, it will be obvious how much she cares about helping you to be a better guitarist and musician. Beatrix pays great attention to the details of your skill-level and technique, and she will create a plan where you will improve as a player and overcome challenges. After just a few lessons with Beatrix, I noticed a different and better, more relaxed approach in my playing."
    },
    {
        name: "Juergen",
        quote: "Beatrix gave me a lot of input and also suggested some easy things to improve my work, finally I made big steps forward and am on a beautiful way of improvement. Thank You Beatrix!"
    },
    {
        name: "Guenter",
        quote: "She is very perceptive and fosters the strengths of her students while at the same time she tries to improve one’s weak spots with a lot of patience. As a student I always feel I’ve learned something new after each lesson so that I look forward to the next one."
    },
    {
        name: "James",
        quote: "Her modern approach to teaching makes it fun and easy to stick to the lessons. She has helped me develop a sense of musicality and improved my technical proficiency. Beatrix's strength lies in her ability to create a personalized learning path while balancing short and long-term goals for her students."
    },
    {
        name: "Chris",
        quote: "Working with Beatrix has been a great pleasure!  Her journey to becoming the great talent that she is allows her to bring unique context and wisdom to her teaching .  Her humor and insight have guided me to improve my technique, musicality, as well as inspiring a joyful relationship to the whole process."
    },
]

var testimonialsHU = [
    {
        name: "Benjamin",
        quote: "Beatrix fantasztikusan adja át, hogyan kell könnyedén és “zeneien” játszani. Könnyen követhető tanulási utat biztosít a céljaim felé a megfelelő darabokkal és gyakorlatokkal, és persze az órái is mindig nagyon szórakoztatóak!"
    },
    {
        name: "Matthieu",
        quote: "Pár perc alatt megtalálta a technikai hibáimat még úgy is, hogy videóhívásban voltunk, ami közvetlenül segített abban, hogy javítsak a játékomon. Beatrix egy nagyszerű tanár, aki valóban képes lehetővé tenni számunkra, hogy gyorsan fejlődjünk annak a hangszernek a tanulásában, melyet annyira szeretünk!"
    },
    {
        name: "Dean",
        quote: "Már az első órán nyilvánvalóvá válik, mennyire fontos számára, hogy segítsen jobb gitárossá és zenésszé válnod. Beatrix nagy figyelmet fordít a technikai részletekre és olyan tervet készít, amellyel tovább fejlődhetsz és leküzdheted a kihívásokat. Mindössze néhány Beatrixtől vett óra után már észrevettem, hogy más, jobb, nyugodtabb a játékom."
    },
    {
        name: "Juergen",
        quote: "Beatrix számos könnyen alkalmazható dolgokat javasolt, hogy javítsak a játékomon. Nagy lépéseket tettem előre, és jó úton vagyok a fejlődés felé. Köszönöm, Beatrix."
    },
    {
        name: "Guenter",
        quote: "Nagyon jó megfigyelő. Támogatja tanítványai erősségeit, ugyanakkor nagy türelemmel próbálja javítani a gyenge pontokat. Úgy érzem, hogy minden órán tanultam valami újat, és alig várom a következőt."
    },
    {
        name: "James",
        quote: "Modern oktatói megközelítése szórakoztatóvá és könnyen követhetővé teszi a leckéket. Segített számomra a zenei érzékem fejlesztésében és a technikai tudásom javításában is. Beatrix erőssége abban rejlik, hogy képes személyre szabott tanulási utat kialakítani, miközben egyensúlyt teremt a rövid és hosszú távú célok között a tanítványai számára."
    },
    {
        name: "Chris",
        quote: "Nagy öröm volt Beatrixszel dolgozni. Az az út, amit azért járt be, hogy ekkora tehetséggé váljon, lehetővé teszi számára, hogy egyedülálló kontextust és bölcsességet hozzon a tanításába. Humora és éleslátása vezetett engem a technikám és a muzikalitásom javításához, valamint inspirál arra, hogy örömet leljek az egész folyamatban."
    },
]

export {testimonialsEN, testimonialsHU}