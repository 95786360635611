<template lang="pug">
article.route.lessons
    section.hero
        .hero-container
            .hero-image-container.transition-start
                picture
                    source(media="(min-width: 1440px)" srcset="../assets/images/lessons/bgLarge.webp")
                    source(media="(min-width: 768px)" srcset="../assets/images/lessons/bgMedium.webp")
                    img.hero-image(src="../assets/images/lessons/bgSmall.webp" alt="")
            .section-container
                .hero-text.transition-start.from-left
                    h1.hero-headline(v-html="$t('lessons.hero.headline')")
                    p.hero-lead {{ $t('lessons.hero.lead') }}
    .content.bright-bg
        section.section
            .section-container.section-container--grid
                .section-text-container.transition-start
                    PromoBox(product="lesson60m" theme="green")
                .transition-start
                    PromoBox(product="11lessons" theme="brown")
        section.section.section.lightgreen-bg
            .section-container.section-container--grid.details-grid
                .section-text-container.transition-start.from-left
                    h2.h2 {{ $t('lessons.details1.title') }}
                    p #[big {{ $t('lessons.details1.text') }}]
                .section-image-container.transition-start
                    img.section-image(src="../assets/images/lessons/d1.webp" alt="")
                .section-image-container.transition-start
                    img.section-image(src="../assets/images/lessons/d2.webp" alt="")
                .section-text-container.transition-start.from-right
                    h2.h2 {{ $t('lessons.details2.title') }}
                    p #[big {{ $t('lessons.details2.text1') }} #[a(href="https://www.sirius.video/" rel="noopener noreferrer" target="_blank") {{ $t('lessons.details2.link') }}]{{ $t('lessons.details2.text2') }}]
                .section-text-container.transition-start.from-left
                    h2.h2 {{ $t('lessons.details3.title') }}
                    p #[big {{ $t('lessons.details3.text') }}]
                .section-image-container.transition-start
                    img.section-image(src="../assets/images/lessons/d3.webp" alt="")
        section.section.bright-bg#videos
            .section-container
                .section-text-container.transition-start
                    h2.h2.tac {{ $t('lessons.playlist.title') }}
                    p.tac #[big {{ $t('lessons.playlist.p1') }}]
                .videos-wrap
                    Videos(video-id="" playlist-id="PLWmENyqQsZgdbIFUQD5lCmWZ2M2b5uBZl", max-results=4).transition-start
                p#faq.tac {{ $t('lessons.playlist.p2') }} 
                    a.dark-link(href="https://www.youtube.com/c/BeatrixGuitar" rel="noopener noreferrer" target="_blank") {{ $t('lessons.playlist.link') }}
        section.section.brown-bg
            .section-container
                .section-text-container.transition-start
                    h2.h2.tac {{ $t('lessons.faq.title') }}
                AccordionList.transition-start
                    AccordionItem
                        template(#summary) {{ $t('lessons.faq.q1') }}
                        template(#icon)
                        p {{ $t('lessons.faq.a1') }}
                    AccordionItem
                        template(#summary) {{ $t('lessons.faq.q2') }}
                        template(#icon)
                        p {{ $t('lessons.faq.a2') }}
                    AccordionItem
                        template(#summary) {{ $t('lessons.faq.q3') }}
                        template(#icon)
                        p {{ $t('lessons.faq.a3') }}
                    AccordionItem
                        template(#summary) {{ $t('lessons.faq.q4') }}
                        template(#icon)
                        p {{ $t('lessons.faq.a4') }}
        section.section.bright-bg
            .section-container
                .section-text-container.transition-start
                    h2.h2.tac {{ $t('lessons.testimonials.title') }}
                Testimonials
</template>

<script>
import Videos from '@/components/Videos'
import Testimonials from '@/components/Testimonials'
import PromoBox from '@/components/PromoBox'
import intersectionObserver from '@/js/mixins/intersectionObserver.js'
import { AccordionList, AccordionItem } from 'vue3-rich-accordion'
import 'vue3-rich-accordion/dist/accordion-library-styles.css'

export default {
    name: 'Lessons',
    components: {
    Videos,
    Testimonials,
    AccordionList,
    AccordionItem,
    PromoBox
},
    mixins: [intersectionObserver],
    mounted () {
        window.scrollTo(0, 0)
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';

.videos.transition-start {
    transition: $sleepy-transition;
    transform: translateY($offset);
    opacity: 0;
}

.videos.transition-end {
    transform: none;
    opacity: 1;
}

.videos-wrap {
    margin: 16px 0;

    @include at(laptopLarge) {
        margin: 24px 0;
    }
}

.list {
    margin-bottom: 24px;

    @include at(laptopLarge) {
        margin-bottom: 0;
    }
}

.details-grid {
    gap: 32px 16px;
}

.details-grid .section-text-container {
    margin-bottom: 16px;
    border-radius: $rootBorderRadius;

    @include at(laptopLarge) {
        margin-bottom: 0;
    }
}

.book-btn {
    display: block;
    margin: 0 auto;
    width: 240px;
    margin-bottom: 16px;
}

.section-image-container img {
    height: 100%;
}
</style>

<style lang="scss" scoped>
@import '@/scss/_utilities';
.accordion-list {
    background-color: transparent;
    max-width: 800px;
    margin: 32px auto 0;
}
.accordion-item {
    background-color: transparent;
    border: 1px solid $bright-outline;

    &:first-child {
        margin-top: 0;
    }
}

.accordion-list .accordion-item :deep(.accordion-item__summary) {
    background-color: transparent;
    transition: $quick-transition;
    border-bottom: 1px solid $bright-outline;
    text-align: left;

    &:hover {
        background-color: #0000001c;
    }

    &:active {
        background-color: transparent;
    }
}

:deep(.accordion-item__summary-title) {
    font: 300 1.125rem/1.4 'Cabin', 'Arial', sans-serif;
    color: $bright-text;
}

.accordion-list .accordion-item > :deep(.accordion-item__content) {
    background-color: #0000001c;
    text-align: left;
    color: $bright-text;
}

:deep(.accordion-item__summary-icon) {
    display: flex;

    &:before {
        content: ' ';
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28"><path fill="%23EEEEEE" d="M480-368.461 631.539-520 603-547.539l-123 123-123-123L328.461-520 480-368.461ZM480.134-120q-74.673 0-140.41-28.339-65.737-28.34-114.365-76.922-48.627-48.582-76.993-114.257Q120-405.194 120-479.866q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194-840 479.866-840q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840-554.806 840-480.134q0 74.673-28.339 140.41-28.34 65.737-76.922 114.365-48.582 48.627-114.257 76.993Q554.806-120 480.134-120ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>') no-repeat center;
        height: 28px;
        width: 28px;
    }
}

:deep(summary::-webkit-details-marker) {
    display: none;
}

.transition-end + :deep(.testimonials .testimonial) {
    opacity: 1;
}
</style>

