
const phpPath = process.env.VUE_APP_API_PATH;

export const getVideo = {
    data() {
        return {
            videoData: new Object(),
        }
    },
    methods: {
        getVideoData(id) {
            fetch(`${phpPath}/getVideo.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                }),
            })
            .then(response => response.json())
            .then(data => {
                this.videoData = data.items;
            })
            .catch(error => {
                console.error('Error fetching video data:', error);
            });

        },
    }
}

export default getVideo