export const intersectionObserver = {
    mounted() {
        const observer = new IntersectionObserver(
            entries => {
              // loop through the entries
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  // add the 'show' class when the element is visible
                  entry.target.classList.add('transition-end')
                }
              })
            },
            { threshold: 0.3 } // set the threshold to 50%
          )
  
          // observe the element
          let elements = document.querySelectorAll('.transition-start')
              elements.forEach(element => {
              observer.observe(element)
        });
    }
}
export default intersectionObserver