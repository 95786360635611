<template lang="pug">
.promo-box(:class="`promo-box--${theme}`")
    template(v-if="product == 'lesson60m'")
        .header
            h3.title {{ $t('lessons.pb1.title') }}
            .header-meta
                img(src="../assets/vector/clock.svg" alt="" aria-hidden="true")
                span {{ $t('lessons.pb1.meta1') }}
                span.sep
                img(src="../assets/vector/online.svg" alt="" aria-hidden="true")
                span {{ $t('lessons.pb1.meta2') }}
        .content
            h4.subtitle {{ $t('lessons.pb1.subtitle1') }}
            ul.list
                li.from-left(style="transition-delay:.3s") #[big #[strong {{ $t('lessons.pb1.li1.strong') }}]{{ $t('lessons.pb1.li1.text') }}]
                li.from-left(style="transition-delay:.6s") #[big #[strong {{ $t('lessons.pb1.li2.strong') }}] {{ $t('lessons.pb1.li2.text') }}]
                li.from-left(style="transition-delay:.9s") #[big #[strong {{ $t('lessons.pb1.li3.strong') }}]{{ $t('lessons.pb1.li3.text') }}]
            //-.video-box
                Videos(video-id="Qq5qZboo1Tg" playlist-id="")
        .footer.from-place(style="transition-delay:1.3s")
            .payment
                img.pplogo(src="../assets/images/lessons/pplogo.webp" alt="PayPal logo")
                h4.price {{ $t('lessons.pb1.price.title') }}
                p {{ $t('lessons.pb1.price.text1') }}#[strong {{ $t('lessons.pb1.price.strong') }}]{{ $t('lessons.pb1.price.text2') }}
            hr
            .policy
                h4 {{ $t('lessons.pb1.policy.title') }}
                p {{ $t('lessons.pb1.policy.text') }}
                    br
                    | #[strong {{ $t('lessons.pb1.policy.strong') }}]
        .clickables.from-place(style="transition-delay:1.6s")
            a.button(:data-cal-link="this.$root.$i18n.locale == 'hu'? 'beatrixguitar/mesterkurzus' : 'beatrixguitar/guitarlesson'" data-cal-namespace="" data-cal-config='{layout":"month_view"}') {{ $t('globals.bookingButton') }}
            h4 {{ $t('lessons.pb1.subtitle2') }}
            a.dark-link(href="#faq") {{ $t('lessons.pb1.anchor') }}
    template(v-if="product == '11lessons'")
        .header
            h3.title “The Only 11 Exercises You Need”
            .header-meta
                img(src="../assets/vector/book.svg" alt="" aria-hidden="true")
                span {{ $t('lessons.pb2.meta1') }}
        .content
            img.content-image.from-place(src="../assets/images/lessons/11ex.webp" alt="" aria-hidden="true")
        .clickables.from-place(style="transition-delay:.3s")
            a.button(href="https://beatrixguitarstudio.myshopify.com/products/the-only-exercises-you-need-top-11-exercises-digital-download-workbook-of-my-favourite-11-exercises-tabs-sheet-music" target="_blank" rel="noopener noreferrer")
                | {{ $t('lessons.pb2.buyButton') }}
                img(v-svg-inline src="../assets/vector/open.svg" alt="" aria-hidden="true")

</template>

<script>
import Videos from '@/components/Videos'

export default {
    name: 'PromoBox',
    components: {
        Videos
    },
    props: {
        theme: {
            type: String,
            default: 'green'
        },
        product: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';

$promo-bg: #F3F3F3;
.promo-box {
    padding: 16px;
    border-radius: $rootBorderRadius;
    background-color: $promo-bg;
    box-shadow: $button-shadow;

    @include at(laptopLarge) {
        padding: 24px 32px;
    }
}

.header {
    border-radius: $rootBorderRadius - 2;
    padding: 16px;
    text-align: center;
    margin-bottom: 24px;
}

.title {
    font: 300 1.75rem/1.1 'Cabin', 'Arial', sans-serif;
    color: $bright-text;
    margin-bottom: 8px;

    @include at(tablet) {
        font-size: 2rem;
    }
}

.promo-box--green .header {
    background: linear-gradient(180deg, rgba(16,48,42,1) 0%, rgba(107,121,106,1) 100%);
}

.promo-box--brown .header {
    background: linear-gradient(0deg, $button-bg-active 0%, $contentBg-brown);
}

.header-meta {
    font: 200 1rem/1.1 'Montserrat', 'Arial', sans-serif;
    color: $bright-text;
    display: flex;
    align-items: center;
    justify-content: center;

    @include at(tablet) {
        font-size: 1.25rem;
    }
}

.header-meta img {
    margin-right: 4px;
}

.sep {
    height: 24px;
    width: 1px;
    background-color: $bright-text;
    margin: 0 8px;
}

.content {
    margin-bottom: 24px;
}

.content-image {
    width: 100%;
    border-radius: $rootBorderRadius - 2;
}

.subtitle {
    font: 500 1.2rem/1.1 'Montserrat', 'Cabin', sans-serif;
    margin-bottom: 16px;

    @include at(tablet) {
        font-size: 1.5rem;
    }
}

.video-box {
    @include at(desktop) {
        height: 351px;
    }
}

.list {
    margin-bottom: 24px;
}

.footer {
    border: 1px solid $contentBg-lightgreen;
    border-radius: 6px;
    padding: 8px 16px;
    margin-bottom: 24px;
}

.payment {
    display: grid;
    grid-template-columns: 42px 1fr;
    grid-template-rows: min-content 1fr;
    grid-gap: 0 8px;
}

.pplogo {
    width: 75%;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: center;
    justify-self: center;

    @include at(laptop) {
        width: 100%;
        grid-row: 1/3;
    }
}

.price {
    font-size: 1.125rem;
    grid-column: 2/-1;
    grid-row: 1/2;
    font-weight: 500;
}

.payment p {
    grid-column: 1/-1;
    grid-row: 2/3;
    font-size: .875rem;
    
    @include at(laptop) {
        grid-column: 2/-1;
    }
}

hr {
    margin: 8px 0;
    border: 0;
    border-top: 1px solid;
    border-top-width: 1px;
    border-color: $contentBg-lightgreen;
}

.policy {
    font-size: .875rem;
}

.policy h4 {
    font-size: 1rem;
    font-weight: 500;
}

.clickables {
    display: flex;
    flex-direction: column;
    text-align: center;

    @include at(tablet) {
        align-items: center;
    }
}

.clickables h4 {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 8px;
}

.clickables .button {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button svg {
    height: 24px;
    width: 24px;
    fill: $bright-text;
    margin-left: 4px;
}
</style>