<template lang="pug">
article.route.route--full-page.music
    .bg-container.transition-start
            picture
                source(media="(min-width:1024px)", srcset="@/assets/images/music/bg.webp")
                img.bg-image(src="@/assets/images/music/bgSmall.webp")
    .content
        section.section
                .section-container
                    .promo-box.transition-start.from-left
                        .promo-text
                            p {{ $t("music.lead") }}
                            h2.h2 {{ $t("music.headline") }}
                            p.from-left(style="transition-delay:.3s") {{ $t("music.p1") }}
                            p.from-left(style="transition-delay:.6s") {{ $t("music.p2") }}
                            p.from-left(style="transition-delay:.9s") {{ $t("music.p3") }}
                            dl.from-left(style="transition-delay:1.1s")
                                dt {{ $t("music.dt1") }}
                                dd {{ $t("music.dd1") }}
                                dt {{ $t("music.dt2") }}
                                dd {{ $t("music.dd2") }}
                            p.from-left(style="transition-delay:1.4s")
                                strong {{ $t("music.p5") }}
                        hr
                        .promo-footer.from-place(style="transition-delay:1.9s")
                            a.button(href='https://beatrixguitarstudio.myshopify.com/products/journey-vinyl' rel="noopener noreferrer" target="_blank")
                                | {{ $t("music.buyButton") }}
                                img(v-svg-inline src="../assets/vector/open.svg")
                            .listen-on
                                p {{ $t("music.p6") }}
                                .promo-icons
                                    a(:href="this.$globals.socials.spotify" :title="$t('music.listenon1')" target="_blank", rel="noopener noreferrer")
                                        img(v-svg-inline src="@/assets/vector/spotify.svg" alt="" aria-hidden="true")
                                    a(:href="this.$globals.socials.appleMusic" :title="$t('music.listenon2')" target="_blank", rel="noopener noreferrer")
                                        img(v-svg-inline src="@/assets/vector/apple-music.svg" alt="" aria-hidden="true")
                                    a(:href="this.$globals.socials.amazon" :title="$t('music.listenon3')" target="_blank", rel="noopener noreferrer")
                                        img(v-svg-inline src="@/assets/vector/amazon.svg" alt="" aria-hidden="true")

</template>

<script>
import intersectionObserver from '@/js/mixins/intersectionObserver.js'

export default {
    name: 'Music',
    mixins: [intersectionObserver],
    mounted () {
        window.scrollTo(0, 0)
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';

.music {
    background-color: black;
}

.bg-container {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
}

.bg-image {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 400px;
    width: 100%;

    @include at(laptop) {
        height: 550px;
    }

    @include at(laptopLarge) {
        height: 100%;
    }
}

.bg-container.transition-start {
    transition: 10s;
    transform: scale(1.05);
}

.bg-container.transition-end {
    transform: scale(1.0)
}

.section-container {
    @include at(laptopLarge) {
        display: flex;
        align-items: center;
        padding-top: 32px;
    }
}

.promo-box img {
    width: 100%;
}

.promo-box {
    background-color: rgba(#00000080, .4);
    padding: 16px;
    flex: 0 0 50%;
    border-radius: $rootBorderRadius;
    margin-top: 310px;

    @include at(laptop) {
        margin-top: 460px;
        margin-bottom: 64px;
    }

    @include at(laptopLarge) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.promo-text p {
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}

.promo-box p:first-child {
    font-family: 'Cabin';
}
.h2 {
    font: 2.5rem/1.2 'Montserrat', 'Arial', sans-serif;
}

.button {
    display: flex;
    align-items: center;
}

.button svg {
    height: 24px;
    width: 24px;
    fill: $bright-text;
    margin-left: 4px;
}

hr {
    margin: 16px 0;
}

.promo-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-direction: column-reverse;

    @include at(laptop) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.listen-on {
    @include at(laptop) {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
.listen-on p {
    text-align: center;

    @include at(laptop) {
        text-align: left;
        margin: 0;
    }
}

.promo-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.listen-on a {
    height: 36px;
}

.listen-on svg {
    height: 36px;
    fill: $bright-text;
    transition: .2s;

    &:hover {
        fill: $button-bg-hover;
        opacity: 1;
    }

    &:active {
        fill: $button-bg-active;
    }
}
</style>

