const phpPath = process.env.VUE_APP_API_PATH;

export const getPlaylist = {
    data() {
        return {
            playlistData: new Object(),
        }
    },
    methods: {
        getPlaylistData(id, maxResults) {
            fetch(`${phpPath}/getPlaylist.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    maxResults: maxResults
                }),
            })
            .then(response => response.json())
            .then(data => {
                this.playlistData = data.items;
            })
            .catch(error => {
                console.error('Error fetching playlist data:', error);
            });

        },
    }
}

export default getPlaylist